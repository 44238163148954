export default () => {
  const options = {
    duration: 5000, // autoplay interval
  };

  const slider = document.querySelector('.slider');

  const lazyImg = slides => {
    const img = slides.querySelector('img');
    if (img.getAttribute('data-src')) {
      img.setAttribute('src', img.getAttribute('data-src'));
    }
    img.onload = () => {
      img.removeAttribute('data-src');
    };
  };

  const showRandom = slides => {
    const slide = slides[Math.floor(Math.random() * slides.length)];
    slide.classList.add('active');
    lazyImg(slide);
  };

  const showNext = slides => {
    let activeIndex;

    slides.forEach((slide, index) => {
      if (slide.classList.contains('active')) {
        activeIndex = index;
        slide.classList.remove('active');
      }
    });

    const nextIndex = (activeIndex + 1) % slides.length;
    slides[nextIndex].classList.add('active');
    lazyImg(slides[nextIndex]);
  };

  const showSlide = (slides, num) => {
    slides.forEach(slide => slide.classList.remove('active'));
    slides[num].classList.add('active');
  };

  if (slider) {
    const slides = Array.from(slider.querySelectorAll('.slider__slide'));

    showRandom(slides);
    setInterval(() => showNext(slides), options.duration);
  }
};
