export default () => {
  window.onscroll = function() {
    stickyHeaderarea();
    stickyButton();
  };

  const navbar = document.querySelector('.header');
  const logo = document.querySelector('.logo--main');

  function stickyHeaderarea() {
    if (navbar) {
      if (window.pageYOffset >= 100) {
        navbar.classList.add('header--sticky');
        logo.classList.add('logo--hide');
      } else {
        navbar.classList.remove('header--sticky');
        logo.classList.remove('logo--hide');
      }
    }
  }

  
  const hero = document.querySelector('.hero-page__wrapper--position');
  const button = document.querySelector('.sticky-abs--hero-page');
  let top = undefined;

  if (button) {
    top = button.offsetTop;
  } else {
    return
  }
  
  function stickyButton() {
    if (hero) {
      if (window.pageYOffset >= top) {
        hero.classList.remove('hero-page__wrapper--position');
        button.classList.remove('sticky-abs--hero-page');
      } else {
        hero.classList.add('hero-page__wrapper--position');
        button.classList.add('sticky-abs--hero-page');
      }
    }
  }
};
