import $ from 'jquery';
import polyfills from './polyfills';
import 'slick-carousel';

import mobileMenu from './modules/mobile-menu';
import slider from './modules/slider';
import anchorsLinks from './modules/anchors-links';
import stickyHeaderarea from './modules/sticky-headerarea';
import slickInit from './modules/slick-init';
import categoryEffect from './modules/category-effect';
import objectFitImages from 'object-fit-images';

($ => {
  polyfills();
  mobileMenu();
  slider();
  anchorsLinks();
  stickyHeaderarea();
  slickInit();
  categoryEffect();
  objectFitImages();
})();
