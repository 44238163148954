export default () => {
  const mobileMenu = document.querySelector('.nav');
  const toggleMenuBtn = document.querySelector('.nav__toggle');
  const toggleHeroBtn = document.querySelector('.hero__button--offcanvas');
  const toggleHeaderLink = document.querySelector('.contacts__offcanvas');

  const toggleMenu = () => {
    mobileMenu.classList.toggle('is-open');
    document.body.classList.toggle('menu-is-open');
  };

  if (toggleMenuBtn) {
    toggleMenuBtn.addEventListener('click', e => {
      e.stopPropagation();

      toggleMenu();
    });

    document.addEventListener('click', e => {
      const target = e.target;
      const its_menu = target == mobileMenu || mobileMenu.contains(target);
      const its_hamburger = target == toggleMenuBtn;
      const menu_is_active = mobileMenu.classList.contains('is-open');

      if (!its_menu && !its_hamburger && menu_is_active) {
        toggleMenu();
      }
    });
  }

  if (toggleHeroBtn) {
    toggleHeroBtn.addEventListener('click', e => {
      e.stopPropagation();

      toggleMenu();
    });
  }

  if (toggleHeaderLink) {
    toggleHeaderLink.addEventListener('click', e => {
      e.stopPropagation();

      toggleMenu();
    });
  }
};
