import $ from 'jquery';

export default () => {
  $(window).scroll(function() {
    $('.categories-list').each(function(i) {
      const bottom_of_object = $(this).offset().top;
      const bottom_of_window = $(window).scrollTop() + screen.height * 0.8;

      if (bottom_of_window > bottom_of_object) {
        $.each($(this).find('.categories-list__item'), function(i, el) {
          setTimeout(function() {
            $(el).addClass('show');
          }, 0 + i * 300);
        });
      }
    });

    $('.categories-list__parallax').each(function(i) {
      const scrollToElem = $(this).offset().top - screen.height / 2.5;
      const winScrollTop = $(window).scrollTop();

      if (document.body.clientWidth > 900) {
        if (winScrollTop > scrollToElem) {
          const scrolled = winScrollTop - scrollToElem;
          $('.categories-list__bg1').css('transform', `translateY(${0 - scrolled * 0.2}px)`);
          $('.categories-list__bg2').css('transform', `translateY(${0 - scrolled * 0.3}px)`);
        }
      }
    });
  });
};
